import moment from 'moment';
import 'moment/locale/id';

export const FL_QUESTIONAIRE_LANDING_PAGE = {
  titleFirstLine: 'Kuisioner',
  titleSecondLine: 'Financial Literacy',
  ctaLandingPage: 'Mulai sekarang!',
  ctaStart: 'Selanjutnya',
  subtitle:
    'Pengelolaan keuangan yang baik menjadi salah satu cara untuk meraih kebebasan finansial. Cek seberapa baik pengelolaan keuanganmu sekarang!',
};

export const FL_QUESTIONAIRE_ITEM = {
  next: 'Berikut',
  back: 'Kembali',
  finish: 'Selesai',
  question: 'Pertanyaan ke-',
};

export const FL_QUESTIONAIRE_RESULT = {
  id: 'ID Kuisioner: #',

  section1Title: 'Anda telah menyelesaikan kuisioner financial literacy!',
  section1Subtitle:
    'Terima kasih anda telah menyelesaikan kuisioner financial literacy. Data yang telah anda masukkan akan kami gunakan untuk meningkatkan pelayanan CU Madani.',

  section2Title: 'Hasil kuisioner anda',
  section2Saving: 'Simpanan',
  section2SavingDesc:
    'Nilai yang ideal untuk simpanan berkisar 15-60 poin. Jika point anda lebih atau kurang dari nilai ideal, ada beberapa hal yang perlu dibenahi.',
  section2Expense: 'Pengeluaran',
  section2ExpenseDesc:
    'Semakin tinggi poin untuk pengeluaran berarti semakin besar alokasi dana yang anda keluarkan untuk konsumsi rumah tangga setiap bulannya.',
  section2Debt: 'Utang',
  section2DebtDesc:
    'Poin utang sebaiknya kurang dari 30 poin. Jika melewati batas tersebut, besar kemungkinan akan mengganggu alokasi dana untuk simpanan maupun pengeluaran bulanan.',

  section3Title: 'Tahapan berikutnya',
  section3Subtitle:
    'Untuk menyelesaikan proses financial literacy, anda dapat mengunduh dan mengisi formulir lanjutan financial literacy sesuai dengan panduan video berikut. Untuk pertanyaan lebih lanjut dapat menghubungi nomor m-care melalui whatsapp atau telepon dengan nomor xxxxx',
  section3Cta: 'Unduh formulir lanjutan financial literacy',
};

export const STATISTIC_PAGE = {
  titleFirst: 'Statistik CU Madani',
  subtitleFirst: `${moment().locale('id').format('MMMM YYYY')}`,
  ctaFirst: 'Lihat lebih lanjut!',
  buttonShowAmount: 'Tampilkan nominal',
  buttonShowAccount: 'Tampilkan jumlah akun',

  memberStatTitle: 'Statistik Keanggotaan',
  assetStatTitle: `Total Asset Dan Modal ${moment().locale('id').format('YYYY')}`,

  savingStatTitle: 'Statistik Simpanan',
  savingStatSubtitle: 'CU Madani telah menjadi tempat yang dipercaya ribuan anggota untuk menyimpan asset dan mengembangkan kekayaan anggota',

  loanStatTitle: 'Statistik Pinjaman',
  laonStatSubtitle:
    'Lewat pinjaman yang tersalurkan CU Madani telah membantu ratusan anggota dalam memenuhi kebutuhan wirausaha, pendidikan, serta beragam kebutuhan hidup anggota',
};

export const EVENT_PAGE = {
  topTitle: 'Pelatihan & Acara',
  topSubtitle: 'Fasilitas khusus bagi anggota CU Madani untuk belajar berbagai hal tentang pengelolaan keuangan dan wirausaha',

  registerFallbackErrorTitle: 'Terjadi kesalahan.',
  registerFallbackErrorDesc: 'Maaf terjadi kesalahan, silahkan coba lagi setelah beberapa saat.',
  registerSuccessTitle: 'Berhasil.',
  registerSuccessDesc: 'Pendaftaran berhasil dilakukan.',

  ctaRegister: 'Daftar',
  ctaNoRegister: 'Langsung datang tanpa perlu mendaftar',
  ctaNoQuota: 'Kuota telah terpenuhi',
  ctaCancel: 'Kembali',
};

export const COMMON_FORM = {
  memberId: 'Nomor Buku Anggota',
  name: 'Nama',
  dob: 'Tanggal Lahir Anggota',
  income: 'Penghasilan Perbulan',
  email: 'Email Aktif',
  phone: 'Nomor ponsel aktif',
};

export const COMMON_FORM_ERROR = {
  memberIdEmpty: 'Nomor buku anggota tidak boleh kosong',
  dobEmpty: 'Tanggal lahir anggota tidak boleh kosong',
  incomeEmpty: 'Penghasilan perbulan tidak boleh kosong',
  incomeInvalid: 'Penghasilan perbulan harus berupa angka',
  emailEmpty: 'Email tidak boleh kosong',
  phoneEmpty: 'Nomor ponsel tidak boleh kosong',
  nameEmpty: 'Nama tidak boleh kosong',
};
