import { ReactNode } from "react";
import { FaLightbulb, FaMoneyBillWaveAlt, FaRegClock, FaShoppingBasket } from "react-icons/fa";

export type Quote = {
  name: string;
  content: string;
  icon: ReactNode;
};

export const QUOTES = [
  {
    name: "Francis Bacon",
    content: "Uang adalah hamba yang baik, namun ia adalah tuan yang buruk",
    icon: <FaMoneyBillWaveAlt size={20} />,
  },
  {
    name: "Jim Rohn",
    content:
      "Waktu lebih berarti daripada uang. Anda dapat mendapatkan lebih banyak uang, tetapi Anda tidak dapat mendapatkan lebih banyak waktu",
    icon: <FaRegClock size={20} />,
  },
  {
    name: "John Ruskin",
    content: "Bukan seberapa banyak orang menghasilkan uang, melainkan untuk tujuan apa uang itu digunakan",
    icon: <FaMoneyBillWaveAlt size={20} />,
  },
  {
    name: "Napoleon Hill",
    content: "Ide adalah titik awal semua kekayaan",
    icon: <FaLightbulb size={20} />,
  },
  {
    name: "Benjamin Franklin",
    content: "Waspadalah terhadap biaya kecil. Sebuah kebocoran kecil bisa menenggelamkan kapal yang besar",
    icon: <FaShoppingBasket />,
  },
];
