import { Box, useDisclosure } from '@chakra-ui/react';
import Navbar from 'components/Navbar';

import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import Footer from 'components/Footer';
import { useCurrentPath } from 'hooks/useCurrentPath';
import Routes from 'routes';
import ScrollToTop from 'ScrollToTop';

export const App = () => {
  const currentPath = useCurrentPath();
  const { isOpen, onToggle } = useDisclosure();

  const showNavbar = () => {
    return currentPath?.hideNavbar ? 'none' : 'block';
  };

  const showNavbarPadding = () => {
    return currentPath?.hideNavbar ? '0px' : '74px';
  };

  return (
    <>
      <ScrollToTop></ScrollToTop>
      <Box display={showNavbar()}>
        <Navbar onToggle={onToggle} isOpen={isOpen}></Navbar>
      </Box>
      <Box paddingTop={showNavbarPadding()} transition="all .1s ease">
        <Routes />
      </Box>
      <Box display={showNavbar()}>
        <Footer></Footer>
      </Box>
    </>
  );
};
