export enum RegistrationMethod {
  INTERNAL = 'INTERNAL',
  EXTERNAL_LINK = 'EXTERNAL_LINK',
  WITHOUT_REGISTRATION = 'WITHOUT_REGISTRATION',
}

export interface EventSchedule {
  id: string;
  date: number;
  startTime: string;
  endTime: string;
  location: string;
  registrationMethod: RegistrationMethod;
  registrationLink: string;
  quota: number;
  numOfRegisteredPerson: number;
  openToPublic: boolean;
}

export interface RegisterEventRequest {
  scheduleId: string;
  memberId: string;
  name: string;
  phoneNumber: string;
}

export interface EventData {
  id: string;
  name: string;
  outline: string;
  shortDescription: string;
  image: string;
  eventSchedules?: EventSchedule[];
}
