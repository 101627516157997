import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Collapse,
  Flex,
  IconButton,
  Image,
  Slide,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import Logo from "assets/logo.png";
import { URLS } from "constants/url";
import { useNavigate } from "react-router-dom";
import DesktopNavbar from "./DesktopNavbar";
import MobileNavbar from "./MobileNavbar";

interface Props {
  isOpen: boolean;
  onToggle: () => void;
}

const Navbar = ({ onToggle, isOpen }: Props) => {
  const navigate = useNavigate();

  return (
    <Box>
      <Flex
        bg={useColorModeValue("white", "gray.700")}
        color={useColorModeValue("gray.600", "white")}
        w="100%"
        minH={"74px"}
        py={{ base: 2 }}
        px={{ base: 2, md: 10 }}
        borderBottom={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.900")}
        align={"center"}
        pos="fixed"
        zIndex={100}
        boxShadow={"lg"}
      >
        <Flex flex={{ base: 0.6, md: "auto" }} ml={{ base: -2 }} display={{ base: "flex", md: "none" }}>
          <IconButton
            onClick={onToggle}
            icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />}
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
          />
        </Flex>
        <Flex flex={{ base: 3 }} justify={{ base: "start", md: "start" }}>
          <Center onClick={() => navigate(URLS.ROOT_LANDING)} _hover={{ cursor: "pointer" }}>
            <Image src={Logo} maxH={useBreakpointValue({ base: "40px", md: "50px" })} paddingRight="2"></Image>
            <Text
              textAlign={useBreakpointValue({ base: "center", md: "left" })}
              fontFamily={"heading"}
              color={useColorModeValue("gray.800", "white")}
              fontWeight="semibold"
              fontSize={useBreakpointValue({ base: "14px", md: "18px" })}
            >
              Business Development Center
            </Text>
          </Center>
          <Flex display={{ base: "none", md: "flex" }} ml={10}>
            <DesktopNavbar />
          </Flex>
        </Flex>

        <Stack flex={{ base: 0.6, md: 0 }} justify={"flex-end"} direction={"row"} spacing={6}>
          <Button as={"a"} fontSize={useBreakpointValue({ base: "xs", md: "sm" })} fontWeight={400} variant={"link"} href={"#"}>
            Daftar
          </Button>
          <Button
            display={{ base: "none", md: "inline-flex" }}
            fontSize={"sm"}
            fontWeight={600}
            color={"white"}
            bg={"blue.400"}
            _hover={{
              bg: "blue.300",
            }}
          >
            Masuk
          </Button>
        </Stack>
      </Flex>

      <Slide direction="left" in={isOpen} style={{ zIndex: 1 }}>
        <Collapse in={isOpen} animateOpacity>
          <MobileNavbar />
        </Collapse>
      </Slide>
    </Box>
  );
};

export default Navbar;
