import { Box, Button, Center, Heading, Image, Stack, Text, useBoolean, VStack, Wrap, WrapItem } from '@chakra-ui/react';
import ContentErrorHandler from 'components/ContentErrorHandler';
import { EVENT_PAGE } from 'constants/text';
import 'font-awesome-animation/css/font-awesome-animation.min.css';
import { useState } from 'react';
import { useGetListQuery } from './api';
import DetailDrawer from './DetailDrawer';

const COLORS = ['#3182ce', '#D69E2E'];

const Event = () => {
  const [detailDrawerOpen, setDetailDrawerOpen] = useBoolean();
  const [selectedId, setSelectedId] = useState('-1');

  const { data, isFetching, isError } = useGetListQuery();

  return (
    <ContentErrorHandler>
      <DetailDrawer id={selectedId} isOpen={detailDrawerOpen} toggleDrawer={setDetailDrawerOpen.toggle} />
      <Center
        dir="col"
        height={{ base: 'calc(20vh + 74px)', md: '30vh' }}
        paddingLeft={{ base: 6, md: 24 }}
        paddingRight={{ base: 6, md: 24 }}
        backgroundColor="blue.600"
      >
        <VStack>
          <Heading fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}>
            <Text as="span" position="relative" color="white">
              {EVENT_PAGE.topTitle}
            </Text>
          </Heading>
          <Text textAlign="center" color="blue.100">
            {EVENT_PAGE.topSubtitle}
          </Text>
        </VStack>
      </Center>

      <ContentErrorHandler isError={isError} isLoading={isFetching} isNoData={data?.length == 0}>
        <Wrap padding={{ base: 7, md: 12 }} spacing={12} justify="center">
          {data?.map((e) => {
            return (
              <WrapItem>
                <Box w={{ base: '85vw', md: '40vw', xl: '25vw' }} boxShadow="2xl" rounded="md" p={6} overflow="hidden">
                  <Box h="210px" bg="gray.100" mt={-6} mx={-6} mb={6} pos="relative">
                    <Image height="100%" width="100%" objectFit="cover" src={e.image} alt="Example" />
                  </Box>
                  <Stack marginTop={6}>
                    <Heading fontSize="l">{e.name}</Heading>
                    <Text h={{ base: 'auto', md: '120px', lg: '110px', xl: '90px' }} fontSize="sm" color="gray.500">
                      {e.shortDescription}
                    </Text>
                    <Button
                      colorScheme="blue"
                      onClick={() => {
                        setSelectedId(e.id);
                        setDetailDrawerOpen.on();
                      }}
                    >
                      Pilih
                    </Button>
                  </Stack>
                </Box>
              </WrapItem>
            );
          })}
        </Wrap>
      </ContentErrorHandler>
    </ContentErrorHandler>
  );
};

export default Event;
