export const URLS = {
  ANY: '/*',
  NOT_FOUND: '/404',
  ROOT_LANDING: '/',

  LOGIN: '/login',
  SIGN_UP: '/sign-up',

  FL_QUESTIONAIRE: '/fl/questionaire',
  FL_QUESTIONAIRE_ITEM: '/fl/questionaire/:id',

  FL_QUESTIONAIRE_RESULT: '/fl/questionaire-result',
  FL_QUESTIONAIRE_RESULT_ITEM: '/fl/questionaire-result/:id',

  FL_TRACKER: '/fl/tracker',

  STATISTIC: '/statistic',

  UMKM: '/umkm',

  EVENT: '/event',
};
