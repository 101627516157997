import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  useColorModeValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
import ContentErrorHandler from "components/ContentErrorHandler";
import { COMMON_FORM, COMMON_FORM_ERROR, FL_QUESTIONAIRE_LANDING_PAGE } from 'constants/text';
import { URLS } from 'constants/url';
import 'font-awesome-animation/css/font-awesome-animation.min.css';
import { Field, Formik } from 'formik';
import { StartQuestionaireRequest } from 'models/flQuestionaire';
import { useNavigate } from 'react-router-dom';
import { useStartQuestionaireMutation } from './api';

const QuestionaireLandingPage = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [startQuestionaire, { isError, isLoading }] = useStartQuestionaireMutation();

  const showErrorToast = (description?: string) => {
    if (description == null || description == undefined) {
      description = 'Maaf terjadi kesalahan, silahkan coba lagi setelah beberapa saat.';
    }

    toast({
      title: 'Terjadi kesalahan',
      size: 'sm',
      description: description,
      status: 'error',
      duration: 9000,
      isClosable: true,
    });
  };

  const save = async (value: StartQuestionaireRequest) => {
    let promise = startQuestionaire(value);

    promise
      .unwrap()
      .then((e) => {
        if (e.isComplete) {
          navigate(URLS.FL_QUESTIONAIRE_RESULT + '/' + encodeURIComponent(e?.currentId), { replace: true });
        } else {
          e?.currentId == null || e?.currentId == undefined
            ? showErrorToast()
            : navigate(URLS.FL_QUESTIONAIRE + '/' + encodeURIComponent(e?.currentId), { replace: true });
        }
      })
      .catch((e) => {
        showErrorToast(e.data.message);
      });
  };

  return (
    <ContentErrorHandler isError={isError} isLoading={isLoading}>
      <Flex
        height={{ base: '100%', md: 'calc(100vh - 74px)' }}
        direction={{ base: 'column', md: 'row' }}
        backgroundImage="url('https://images.pexels.com/photos/6963847/pexels-photo-6963847.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1')"
        backgroundSize={'cover'}
        backgroundPosition="bottom"
      >
        <Stack height={'100%'} width={'100%'} direction={{ base: 'column', md: 'row' }} bgGradient="linear(to-r, blue.700, transparent)">
          <Center height={{ base: 'auto', md: '100%' }} p={8} flex={1}>
            <Stack spacing={6} w={'full'} maxW={'lg'}>
              <Heading fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}>
                <Text as={'span'} position={'relative'} color="white">
                  {FL_QUESTIONAIRE_LANDING_PAGE.titleFirstLine}
                </Text>
                <br />
                <Text color={'blue.100'} as={'span'}>
                  {FL_QUESTIONAIRE_LANDING_PAGE.titleSecondLine}
                </Text>
              </Heading>
              <Text fontSize={{ base: 'md', lg: 'lg' }} color={'gray.100'}>
                {FL_QUESTIONAIRE_LANDING_PAGE.subtitle}
              </Text>
            </Stack>
          </Center>
          <Center flex={1}>
            <Box
              background={useColorModeValue('whiteAlpha.700', 'blackAlpha.700')}
              marginBottom={{ base: 10, md: 0 }}
              marginX="2"
              padding="6"
              rounded={'lg'}
            >
              <Formik
                initialValues={{
                  memberId: '',
                  dob: '',
                  income: 0,
                  email: '',
                }}
                onSubmit={async (values, actions) => {
                  await save(values);
                  actions.setSubmitting(false);
                }}
              >
                {({ handleSubmit, errors, touched }) => (
                  <form onSubmit={handleSubmit}>
                    <VStack spacing={4} align="flex-start">
                      <FormControl isInvalid={!!errors.memberId && touched.memberId}>
                        <FormLabel htmlFor="password">{COMMON_FORM.memberId}</FormLabel>
                        <Field
                          as={Input}
                          id="memberId"
                          name="memberId"
                          type="text"
                          validate={(value: any) => {
                            let error;
                            if (!value) {
                              error = COMMON_FORM_ERROR.memberIdEmpty;
                            }
                            return error;
                          }}
                        />
                        <FormErrorMessage>{errors.memberId}</FormErrorMessage>
                      </FormControl>
                      <FormControl isInvalid={!!errors.dob && touched.dob}>
                        <FormLabel htmlFor="dob">{COMMON_FORM.dob}</FormLabel>
                        <Field
                          as={Input}
                          id="dob"
                          name="dob"
                          type="date"
                          validate={(value: any) => {
                            let error;
                            if (!value) {
                              error = COMMON_FORM_ERROR.dobEmpty;
                            }
                            return error;
                          }}
                        />
                        <FormErrorMessage>{errors.dob}</FormErrorMessage>
                      </FormControl>
                      <FormControl isInvalid={!!errors.income && touched.income}>
                        <FormLabel htmlFor="income">{COMMON_FORM.income}</FormLabel>
                        <Field
                          as={Input}
                          id="income"
                          name="income"
                          type="text"
                          validate={(value: any) => {
                            let error;
                            if (isNaN(value)) {
                              error = COMMON_FORM_ERROR.incomeInvalid;
                            }
                            if (!value) {
                              error = COMMON_FORM_ERROR.incomeEmpty;
                            }
                            return error;
                          }}
                        />
                        <FormErrorMessage>{errors.income}</FormErrorMessage>
                      </FormControl>
                      <FormControl isInvalid={!!errors.email && touched.email}>
                        <FormLabel htmlFor="email">{COMMON_FORM.email}</FormLabel>
                        <Field
                          as={Input}
                          id="email"
                          name="email"
                          type="email"
                          validate={(value: any) => {
                            let error;
                            if (!value) {
                              error = COMMON_FORM_ERROR.emailEmpty;
                            }
                            return error;
                          }}
                        />
                        <FormErrorMessage>{errors.email}</FormErrorMessage>
                      </FormControl>
                      <Button type="submit" colorScheme="blue" width="full">
                        {FL_QUESTIONAIRE_LANDING_PAGE.ctaStart}
                      </Button>
                    </VStack>
                  </form>
                )}
              </Formik>
            </Box>
          </Center>
        </Stack>
      </Flex>
    </ContentErrorHandler>
  );
};

export default QuestionaireLandingPage;
