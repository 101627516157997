import * as React from "react";
import {
  useColorMode,
  useColorModeValue,
  IconButton,
  IconButtonProps,
  Box,
  Center,
  Heading,
  Text,
  VStack,
  Spinner,
  Button,
} from "@chakra-ui/react";
import { FaFrown, FaInfoCircle, FaMoon, FaSun } from 'react-icons/fa';
import { QUOTES } from './text';
import { useNavigate } from 'react-router-dom';

interface Props {
  isError?: any;
  isLoading?: any;
  isNoData?: any;
  children: React.ReactNode;
}

const ContentErrorHandler = (props: Props) => {
  let index = new Date().getHours() % QUOTES.length;

  let navigate = useNavigate();
  let subtitleColor = useColorModeValue('blackAlpha.600', 'whiteAlpha.600');

  if (props.isLoading)
    return (
      <Center w="full" h="calc(100vh-74px)" padding="20% ">
        <VStack textAlign="center">
          {QUOTES[index].icon}
          <Text fontSize="md">{QUOTES[index].content}</Text>
          <Text fontSize="sm" color={subtitleColor}>
            - {QUOTES[index].name} -
          </Text>
          <Spinner color="blue.500" />
        </VStack>
      </Center>
    );

  if (props.isError)
    return (
      <Center w="full" h="calc(100vh-74px)" padding="20% ">
        <VStack textAlign="center">
          <FaInfoCircle color="red" />
          <Text fontSize="md">Terjadi kesalahan</Text>
          <Text fontSize="sm" color={subtitleColor}>
            Maaf terjadi kesalahan pada server kami, silahkan coba lagi dalam beberapa saat
          </Text>
          <Button onClick={() => navigate('/')}>Beranda</Button>
        </VStack>
      </Center>
    );

  if (props.isNoData)
    return (
      <Center w="full" h="calc(100vh-74px)" padding="20% ">
        <VStack textAlign="center">
          <FaFrown color="blue" />
          <Text fontSize="md">Tidak ada data</Text>
          <Text fontSize="sm" color={subtitleColor}>
            Maaf tidak ada data yang bisa kami tampilkan untuk saat ini
          </Text>
          <Button onClick={() => navigate('/')}>Beranda</Button>
        </VStack>
      </Center>
    );

  return <Box>{props.children}</Box>;
};

export default ContentErrorHandler;
