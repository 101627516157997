import { Button, Center, Flex, Heading, Stack, Text } from "@chakra-ui/react";
import { FaArrowRight } from "react-icons/fa";
import "font-awesome-animation/css/font-awesome-animation.min.css";
import { useNavigate } from "react-router-dom";
import { FL_QUESTIONAIRE_LANDING_PAGE } from "constants/text";
import { URLS } from "constants/url";

const LandingPage = () => {
  const navigate = useNavigate();

  return (
    <Flex
      height="calc(100vh - 74px)"
      direction={{ base: "column", md: "row" }}
      backgroundImage="url('https://images.pexels.com/photos/6963847/pexels-photo-6963847.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1')"
      backgroundSize={"cover"}
      backgroundPosition="bottom"
    >
      <Stack height={"100%"} width={"100%"} direction={{ base: "column", md: "row" }} bgGradient="linear(to-r, blue.700, transparent)">
        <Center p={8} flex={1}>
          <Stack spacing={6} w={"full"} maxW={"lg"}>
            <Heading fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }}>
              <Text as={"span"} position={"relative"} color="white">
                {FL_QUESTIONAIRE_LANDING_PAGE.titleFirstLine}
              </Text>
              <br />
              <Text color={"blue.100"} as={"span"}>
                {FL_QUESTIONAIRE_LANDING_PAGE.titleSecondLine}
              </Text>
            </Heading>
            <Text fontSize={{ base: "md", lg: "lg" }} color={"gray.100"}>
              {FL_QUESTIONAIRE_LANDING_PAGE.subtitle}
            </Text>
            <Stack direction={{ base: "column", md: "row" }} spacing={4}>
              <Button
                onClick={() => navigate(URLS.FL_QUESTIONAIRE)}
                rounded={"full"}
                colorScheme={"blue"}
                rightIcon={<FaArrowRight className="fa faa-horizontal animated" />}
              >
                {FL_QUESTIONAIRE_LANDING_PAGE.ctaLandingPage}
              </Button>
            </Stack>
          </Stack>
        </Center>
        <Flex flex={1} display={{ base: "none", md: "block" }}></Flex>
      </Stack>
    </Flex>
  );
};

export default LandingPage;
