import { Box, Button, Center, Flex, Heading, Stack, Stat, StatLabel, StatNumber, Text, useBoolean, VStack, Wrap, WrapItem } from '@chakra-ui/react';
import ContentErrorHandler from 'components/ContentErrorHandler';
import { STATISTIC_PAGE } from 'constants/text';
import 'font-awesome-animation/css/font-awesome-animation.min.css';
import { StatisticData } from 'models/statistic';
import { useRef } from 'react';
import { FaArrowDown } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { Bar, BarChart, CartesianGrid, Label, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useScreen } from 'usehooks-ts';
import { useGetStatisticQuery } from './api';

const COLORS = ['#3182ce', '#D69E2E'];

const Statistic = () => {
  const navigate = useNavigate();
  const screenSize = useScreen();

  const firstRef = useRef<null | HTMLDivElement>(null);
  const secondRef = useRef<null | HTMLDivElement>(null);
  const thirdRef = useRef<null | HTMLDivElement>(null);
  const forthRef = useRef<null | HTMLDivElement>(null);

  const { data, isFetching, isError, refetch } = useGetStatisticQuery();

  const [savingShowAmount, setSavingShowAmount] = useBoolean(true);
  const [loanShowAmount, setLoanShowAmount] = useBoolean(true);

  const handleScrollRef = (type: string) => {
    switch (type) {
      case 'firstRef':
        if (firstRef != null && firstRef.current != null) {
          firstRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
        break;

      case 'secondRef':
        if (secondRef != null && secondRef.current != null) {
          secondRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        break;

      case 'thirdRef':
        if (thirdRef != null && thirdRef.current != null) {
          thirdRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        break;
      case 'forthRef':
        if (forthRef != null && forthRef.current != null) {
          forthRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
        break;

      default:
        break;
    }
  };

  const renderStatistic = (type: string, dataList: StatisticData[]) => {
    let result: any[] = [];

    let backgroundColor = 'white';
    let textColor = 'black';
    let borderColor = 'transparent';
    let borderWidth = 0;

    if (type == 'saving') {
      backgroundColor = 'blue.600';
      textColor = 'white';
    } else if (type == 'loan') {
      backgroundColor = 'whiteAlpha.700';
    } else if (type == 'member' || type == 'asset') {
      backgroundColor = 'blackAlpha.400';
      textColor = 'white';
      borderColor = 'blue.600';
      borderWidth = 2;
    }

    for (let datum of dataList) {
      result.push(
        <WrapItem
          background={backgroundColor}
          width={{ base: '80vw', md: '25vw' }}
          padding={4}
          paddingBottom={2}
          borderRadius={12}
          borderColor={borderColor}
          borderWidth={borderWidth}
        >
          <Stat color={textColor}>
            <StatLabel whiteSpace={'pre-wrap'}>{datum.label}</StatLabel>
            <StatNumber>{datum.amount}</StatNumber>
          </Stat>
        </WrapItem>,
      );
    }
    return result;
  };

  const customBarTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <Box backgroundColor="white" padding={2} shadow="lg">
          <Text>Bulan : {label}</Text>
          <Text>Total Asset : {payload[0].value} M</Text>
          <Text>Total Modal : {payload[1].value} M</Text>
        </Box>
      );
    }

    return null;
  };

  const customPieLabel = (data: any) => {
    const RADIAN = Math.PI / 180;
    const radius = data.innerRadius + (data.outerRadius - data.innerRadius) * 0.5;
    const x = data.cx + radius * Math.cos(-data.midAngle * RADIAN);
    const y = data.cy + radius * Math.sin(-data.midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > data.cx ? 'start' : 'end'} dominantBaseline="central">
        {data.payload.payload.valueLabel}
      </text>
    );
  };

  const getData = () => {
    let temp = data?.asset ?? [];
    if (screenSize?.width ?? 0 < 768) {
      return temp.length > 1 ? [temp[0], temp.slice(-1)[0]] : [temp[0]];
    }
    return temp;
  };

  const getChartHeight = () => {
    let screenHeight = screenSize?.height ?? 0;
    if (screenHeight < 768) {
      return screenHeight / 2.4;
    }
    return screenHeight / 1.6;
  };

  const renderBarChart = () => {
    return (
      <ResponsiveContainer width="100%" height={getChartHeight()}>
        <BarChart
          data={getData()}
          margin={{
            top: 25,
          }}
        >
          <YAxis fill="white" tick={{ fill: 'white' }} tickCount={8}>
            <Label
              style={{
                fill: 'white',
              }}
              position="center"
              dx={-20}
              angle={-90}
              value={'Nominal (milyar)'}
            />
          </YAxis>
          <XAxis dataKey="date" fill="white" tick={{ fill: 'white' }} />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip content={customBarTooltip} cursor={{ fillOpacity: '0.3' }} />
          <Legend formatter={(value, entry, index) => (value == 'asset' ? 'Total Aset' : 'Total Modal')} />
          <Bar id="asset" key="asset" stackId="asset" type="monotone" dataKey="asset" fill="#3182ce" label="Aset">
            <LabelList dataKey="assetLabel" position="insideTop" fill="white" />
          </Bar>
          <Bar id="modal" key="modal" stackId="modal" type="monotone" dataKey="modal" fill="#D69E2E" label="Modal">
            <LabelList dataKey="modalLabel" position="insideTop" fill="white" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  };

  return (
    <ContentErrorHandler isError={isError} isLoading={isFetching}>
      <Flex
        height="calc(100vh - 74px)"
        direction={{ base: 'column', md: 'row' }}
        backgroundImage="url('https://images.pexels.com/photos/669614/pexels-photo-669614.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2')"
        backgroundSize={'cover'}
        backgroundPosition="bottom"
      >
        <Stack height={'100%'} width={'100%'} direction={{ base: 'column', md: 'row' }} bgGradient="linear(to-r, blue.700, transparent)">
          <Center p={8} flex={1}>
            <Stack spacing={6} w={'full'} maxW={'lg'}>
              <Heading fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}>
                <Text as={'span'} position={'relative'} color="white">
                  {STATISTIC_PAGE.titleFirst}
                </Text>
                <br />
                <Text color={'blue.100'} as={'span'}>
                  {STATISTIC_PAGE.subtitleFirst}
                </Text>
              </Heading>
              <Stack direction={{ base: 'column', md: 'row' }} spacing={4}>
                <Button
                  onClick={() => handleScrollRef('firstRef')}
                  rounded={'full'}
                  colorScheme={'blue'}
                  rightIcon={<FaArrowDown className="fa faa-vertical animated" />}
                >
                  {STATISTIC_PAGE.ctaFirst}
                </Button>
              </Stack>
            </Stack>
          </Center>
          <Flex flex={1} display={{ base: 'none', md: 'block' }}></Flex>
        </Stack>
      </Flex>

      <Flex
        ref={firstRef}
        height="calc(100vh)"
        direction={{ base: 'column', md: 'row' }}
        backgroundImage="url('https://ik.imagekit.io/madani/generalInfo/member_stats_bg.JPG')"
        backgroundSize={'cover'}
        backgroundPosition="top"
      >
        <Stack height={'100%'} width={'100%'} direction="column" bgGradient="linear(to-b, black, blackAlpha.600)">
          <Center flex={1} paddingTop={{ base: '104px', md: '74px' }} paddingBottom={{ base: 6, md: 0 }}>
            <Heading fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }}>
              <Text as={'span'} position={'relative'} color="white">
                {STATISTIC_PAGE.memberStatTitle}
              </Text>
            </Heading>
          </Center>
          <Wrap spacing={{ base: 10, md: 12 }} align="center" justify="center" direction="row" paddingLeft={12} paddingRight={12} paddingBottom={12}>
            {renderStatistic('member', data?.member ?? [])}
          </Wrap>
          <Flex flex={{ base: 0, md: 1 }} display={{ base: 'none', md: 'block' }}></Flex>
          <Center color="white" flex={1} paddingBottom={{ base: 6, md: 0 }}>
            <FaArrowDown onClick={() => handleScrollRef('secondRef')} cursor="pointer" className="fa faa-vertical animated"></FaArrowDown>
          </Center>
        </Stack>
      </Flex>

      <Flex minHeight="calc(100vh)" direction="column">
        <Stack ref={secondRef} paddingTop="104px" flex={1} direction={{ base: 'column', md: 'row' }} alignContent="center" justifyContent={'center'}>
          <Center pl={{ base: 12, md: 20 }} pr={{ base: 12, md: 0 }} pb={{ base: 8, md: 0 }} flex={1}>
            <VStack>
              <Heading fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} textAlign={'center'}>
                <Text as={'span'} position="relative" color="blue.500">
                  {STATISTIC_PAGE.savingStatTitle}
                </Text>
              </Heading>
              <Text textAlign="center" fontSize={{ base: 'md', lg: 'lg' }}>
                {STATISTIC_PAGE.savingStatSubtitle}
              </Text>
              <Button colorScheme="yellow" onClick={setSavingShowAmount.toggle} color="gray.900">
                {savingShowAmount ? STATISTIC_PAGE.buttonShowAccount : STATISTIC_PAGE.buttonShowAmount}
              </Button>
            </VStack>
          </Center>
          <Wrap flex={2} spacing="8" align="center" justify="center" direction="row">
            {renderStatistic('saving', savingShowAmount ? data?.savingAmount ?? [] : data?.savingAccount ?? [])}
          </Wrap>
        </Stack>
        <Center color="blue.500" flex={1} paddingBottom={6} paddingTop={6}>
          <FaArrowDown onClick={() => handleScrollRef('thirdRef')} cursor="pointer" className="fa faa-vertical animated"></FaArrowDown>
        </Center>
      </Flex>

      <Flex minHeight="calc(100vh)" direction="column" background="blue.500">
        <Stack ref={thirdRef} paddingTop="104px" flex={1} direction={{ base: 'column', md: 'row' }} alignContent="center" justifyContent={'center'}>
          <Center pl={{ base: 12, md: 20 }} pr={{ base: 12, md: 0 }} pb={{ base: 8, md: 0 }} flex={1}>
            <VStack>
              <Heading fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} textAlign={'center'}>
                <Text as={'span'} position="relative" color="gray.900">
                  {STATISTIC_PAGE.loanStatTitle}
                </Text>
              </Heading>
              <Text textAlign="center" fontSize={{ base: 'md', lg: 'lg' }} color="white">
                {STATISTIC_PAGE.laonStatSubtitle}
              </Text>
              <Button colorScheme="yellow" onClick={setLoanShowAmount.toggle} color="gray.900">
                {loanShowAmount ? STATISTIC_PAGE.buttonShowAccount : STATISTIC_PAGE.buttonShowAmount}
              </Button>
            </VStack>
          </Center>
          <Wrap flex={2} spacing="8" align="center" justify="center" direction="row">
            {renderStatistic('loan', loanShowAmount ? data?.loanAmount ?? [] : data?.loanAccount ?? [])}
          </Wrap>
        </Stack>
        <Center color="white" flex={1} paddingBottom={6} paddingTop={6}>
          <FaArrowDown onClick={() => handleScrollRef('forthRef')} cursor="pointer" className="fa faa-vertical animated"></FaArrowDown>
        </Center>
      </Flex>

      <Flex
        backgroundImage="url('https://images.pexels.com/photos/259209/pexels-photo-259209.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2')"
        backgroundSize={'cover'}
        backgroundPosition="top"
        ref={forthRef}
        height="calc(100vh)"
        direction={{ base: 'column', md: 'row' }}
      >
        <Stack height={'100%'} width={'100%'} direction="column" bgGradient="linear(to-b, black, blackAlpha.700)">
          <Flex flex={1} display={{ base: 'none', md: 'block' }}></Flex>
          <Center flex={1} paddingTop={{ base: '104px', md: '74px' }} paddingLeft={6} paddingRight={6}>
            <Heading fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} color="white" textAlign="center">
              {STATISTIC_PAGE.assetStatTitle}
            </Heading>
          </Center>
          <Center flex={4} paddingBottom={6} paddingLeft={{ base: 3, md: 12 }} paddingRight={{ base: 5, md: 12 }}>
            {renderBarChart()}
          </Center>
          <Flex flex={2} display={{ base: 'none', md: 'block' }}></Flex>
        </Stack>
      </Flex>
    </ContentErrorHandler>
  );
};

export default Statistic;
