import {
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  HStack,
  Icon,
  Image,
  List,
  ListIcon,
  ListItem,
  Tag,
  Text,
  useBoolean,
  VStack,
} from '@chakra-ui/react';
import ContentErrorHandler from 'components/ContentErrorHandler';
import { EVENT_PAGE } from 'constants/text';
import 'font-awesome-animation/css/font-awesome-animation.min.css';
import { EventSchedule, RegistrationMethod } from 'models/event';
import moment from 'moment';
import { useState } from 'react';
import { FaCalendar, FaClock, FaLocationArrow, FaTimesCircle, FaUsers } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useScreen } from 'usehooks-ts';
import { useGetDetailQuery } from '../api';
import RegisterDrawer from '../RegisterDrawer';

const COLORS = ['#3182ce', '#D69E2E'];

type Props = {
  id: string;
  isOpen: boolean;
  toggleDrawer: any;
};

const DetailDrawer = ({ id, isOpen, toggleDrawer }: Props) => {
  const screenSize = useScreen();
  const [registerDrawerOpen, setRegisterDrawerOpen] = useBoolean();
  const [selectedSchedule, setSelectedSchedule] = useState<EventSchedule | null>(null);

  const { data, isFetching, isError, refetch } = useGetDetailQuery(id, {
    skip: id == '-1',
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
    refetchOnFocus: true,
  });

  const renderRegisterButton = (schedule: EventSchedule) => {
    let registrationMethod = RegistrationMethod[schedule.registrationMethod];
    if (registrationMethod == RegistrationMethod.EXTERNAL_LINK) {
      return (
        <Button size="xs" w="full" colorScheme="blue">
          <a href={schedule.registrationLink} target="_blank">
            {EVENT_PAGE.ctaRegister}
          </a>
        </Button>
      );
    } else if (registrationMethod == RegistrationMethod.INTERNAL) {
      return (
        <Button
          colorScheme="blue"
          w="full"
          disabled={schedule.numOfRegisteredPerson >= schedule.quota}
          size="xs"
          onClick={() => {
            setRegisterDrawerOpen.on();
            setSelectedSchedule(schedule);
          }}
        >
          {EVENT_PAGE.ctaRegister}
        </Button>
      );
    } else {
      return (
        <Tag color="blue.500" size="sm">
          {EVENT_PAGE.ctaNoRegister}
        </Tag>
      );
    }
  };

  const renderScheduleList = () => {
    return data?.eventSchedules?.map((e: EventSchedule) => {
      return (
        <Box marginLeft={1} flexShrink="0" w="200px" padding={3} bg="white" rounded="md" boxShadow="lg">
          <List h="150px" spacing={2} fontSize={14}>
            <ListItem>
              <ListIcon as={FaCalendar} color="blue.500" />
              {moment.unix(e?.date || 0).format('dddd, DD MMM YYYY')}
            </ListItem>
            <ListItem>
              <ListIcon as={FaClock} color="blue.500" />
              {e.startTime} - {e.endTime}
            </ListItem>
            <ListItem>
              <ListIcon as={FaLocationArrow} color="blue.500" />
              {e.location}
            </ListItem>
            <ListItem>
              <ListIcon as={FaUsers} color="blue.500" />
              {e.registrationMethod == RegistrationMethod.INTERNAL ? `${e.numOfRegisteredPerson}/${e.quota} orang` : `${e.quota} orang`}
            </ListItem>
          </List>
          {renderRegisterButton(e)}
        </Box>
      );
    });
  };

  const renderScheduleNotAvailable = () => {
    return (
      <Box marginLeft={1} flexShrink="0" w="200px" padding={2} bg="white" rounded="md" boxShadow="lg">
        <Center h="150px">
          <VStack>
            <Icon as={FaTimesCircle} color="red"></Icon>
            <Text textAlign="center" fontSize="14">
              Tidak tersedia, silahkan hubungi m-care untuk info lebih lanjut
            </Text>
          </VStack>
        </Center>
      </Box>
    );
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={toggleDrawer} size={'lg'}>
      <DrawerOverlay />
      <RegisterDrawer
        refetchDetail={refetch}
        isOpen={registerDrawerOpen}
        toggleDrawer={setRegisterDrawerOpen.toggle}
        eventName={data?.name || ''}
        schedule={selectedSchedule}
      />
      <DrawerContent>
        <ContentErrorHandler isError={isError} isLoading={isFetching}>
          <DrawerHeader p="0">
            <DrawerCloseButton color="white" bgColor="whiteAlpha.400" zIndex={100} />
            <Box h="30vh" w="100%" bg="gray.100" position="relative">
              <Box bgGradient="linear(to-t, blue.900, transparent)" position="absolute" left={0} top={0} width="full" height="full" />
              <Image height="100%" width="100%" objectFit="cover" src={data?.image} alt="Example" />
              <Heading
                fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }}
                position="absolute"
                bottom={6}
                left={8}
                color="whiteAlpha.900"
                paddingRight={8}
              >
                {data?.name}
              </Heading>
            </Box>
          </DrawerHeader>

          <DrawerBody paddingX={8} paddingTop={6} paddingBottom={10} backgroundColor="gray.50">
            <Text marginBottom={2} fontWeight="bold">
              Deskripsi
            </Text>
            <Text
              fontSize={14}
              dangerouslySetInnerHTML={{ __html: data?.outline || '' }}
              sx={{
                'ul, ol': {
                  paddingLeft: '20px',
                },
                p: {
                  marginBottom: '6px',
                },
              }}
            />
            <Text marginTop={4} fontWeight="bold">
              Jadwal
            </Text>
            <HStack spacing={4} overflowX="auto" paddingY={4}>
              {(data?.eventSchedules?.length || 0) > 0 ? renderScheduleList() : renderScheduleNotAvailable()}
            </HStack>
          </DrawerBody>
        </ContentErrorHandler>
      </DrawerContent>
    </Drawer>
  );
};

export default DetailDrawer;
