import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Skeleton,
  SkeletonText,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import ContentErrorHandler from "components/ContentErrorHandler";
import { FL_QUESTIONAIRE_ITEM } from "constants/text";
import "font-awesome-animation/css/font-awesome-animation.min.css";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAnswerMutation, useGetQuery } from "./api";

const Questionaire = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [answer, setAnswer] = useState<string>("0");
  const { data, isFetching, isError } = useGetQuery(encodeURIComponent(id || ""), {
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
  });
  const [answerQuestion, answerResult] = useAnswerMutation();

  useEffect(() => {
    if (data && data.isComplete) {
      navigate("/fl/questionaire-result/" + encodeURIComponent(id ?? ""), { replace: true });
    }

    if (data && data?.previousAnswer) {
      setAnswer(data?.previousAnswer.toString());
    } else {
      setAnswer("0");
    }
  }, [data]);

  const handleSelectedColor = (index: number) => {
    if (isFetching) return "white";
    return Number(answer) == index ? "yellow.400" : "whiteAlpha";
  };

  const handleSelectedHoverColor = (index: number) => {
    return Number(answer) == index ? "yellow.400" : "blackAlpha.100";
  };

  const handleNext = () => {
    if (data?.isLastQuestion) {
      onOpen();
    } else {
      answerQuestionHandler();
    }
  };

  const answerQuestionHandler = () => {
    answerQuestion({ answer: Number(answer), id: id ?? "" })
      .unwrap()
      .then((e) => {
        if (e.isComplete) {
          navigate("/fl/questionaire-result/" + encodeURIComponent(e?.currentId ?? ""), { replace: true });
        } else {
          navigate("/fl/questionaire/" + encodeURIComponent(e?.currentId ?? ""), { replace: true });
        }
      });
  };

  const handlePrev = () => {
    navigate("/fl/questionaire/" + encodeURIComponent(data?.previousId ?? ""), { replace: true });
  };

  return (
    <ContentErrorHandler isLoading={isFetching} isError={false}>
      <Stack
        height="100vh"
        width={"100%"}
        direction={{ base: "column", md: "row" }}
        bgGradient={{ base: "linear(to-b, blue.700, white)", md: "linear(to-r, blue.700, blue.200)" }}
      >
        <Center textAlign={{ base: "center", md: "right" }} height={{ base: "auto", md: "100%" }} p={8} flex={1}>
          <Stack align={{ base: "center", md: "end" }} spacing={6} w={"full"} maxW={"lg"}>
            <Skeleton isLoaded={!isFetching}>
              <Text fontSize={{ base: "md", lg: "lg" }} color={"gray.100"}>
                {FL_QUESTIONAIRE_ITEM.question + ((data?.currentIdx ?? 0) + 1)}
              </Text>
            </Skeleton>
            <Heading fontSize={{ base: "xl", md: "2xl", lg: "3xl" }}>
              <SkeletonText w="100%" skeletonHeight="28px" noOfLines={2} isLoaded={!isFetching}>
                <Text as={"span"} position={"relative"} color="white">
                  {data?.question}
                </Text>
              </SkeletonText>
            </Heading>
            <HStack>
              <Box display={data?.previousId ? "block" : "none"}>
                <Button
                  disabled={isFetching}
                  onClick={() => handlePrev()}
                  colorScheme="whiteAlpha"
                  size={"sm"}
                  display={{ base: "none", md: "block" }}
                >
                  {FL_QUESTIONAIRE_ITEM.back}
                </Button>
              </Box>
              <Button
                disabled={isFetching}
                onClick={() => handleNext()}
                colorScheme="yellow"
                size={"sm"}
                display={{ base: "none", md: "block" }}
              >
                {data?.isLastQuestion ? FL_QUESTIONAIRE_ITEM.finish : FL_QUESTIONAIRE_ITEM.next}
              </Button>
            </HStack>
          </Stack>
        </Center>
        <Center background={"white"} flex={2}>
          <Box width={"100%"} marginBottom={{ base: 10, md: 0 }} marginX="2" padding="6" rounded="lg">
            <FormControl as="fieldset" isInvalid={Number(answer) < 0 || Number(answer) >= (data?.answers?.length ?? 0)}>
              <RadioGroup onChange={setAnswer} value={answer}>
                <VStack alignItems={"flex-start"}>
                  {data?.answers?.map((option, key) => (
                    <Box
                      key={key}
                      borderColor="blackAlpha.300"
                      background={handleSelectedColor(key)}
                      borderWidth={1}
                      padding={2}
                      w={"100%"}
                      textAlign="left"
                      rounded={"md"}
                      _hover={{
                        background: handleSelectedHoverColor(key),
                        borderColor: "blackAlpha.100",
                      }}
                      color="black"
                      transition="background-color 100ms linear"
                    >
                      <Skeleton isLoaded={!isFetching}>
                        <Radio value={key.toString()} background={"white"} w="100%">
                          {option}
                        </Radio>
                      </Skeleton>
                    </Box>
                  ))}
                </VStack>
              </RadioGroup>
              <FormErrorMessage>Harus memilih 1 jawaban</FormErrorMessage>
            </FormControl>
            <VStack spacing={3}>
              <Button
                disabled={isFetching}
                onClick={() => handleNext()}
                marginTop={12}
                colorScheme="yellow"
                size={"sm"}
                w="full"
                display={{ base: "block", md: "none" }}
              >
                {data?.isLastQuestion ? FL_QUESTIONAIRE_ITEM.finish : FL_QUESTIONAIRE_ITEM.next}
              </Button>
              <Button
                disabled={isFetching}
                onClick={() => handlePrev()}
                colorScheme="gray"
                color="black"
                size={"sm"}
                w="full"
                display={{ base: data?.previousId ? "block" : "none", md: "none" }}
              >
                {FL_QUESTIONAIRE_ITEM.back}
              </Button>
            </VStack>
          </Box>
        </Center>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Anda akan mengirim repons kuisioner</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              Apakah data yang ada isi sudah sesuai? Jika sudah, silahkan tekan tombol "Kirim" untuk mengirimkan respons. Jika belum,
              silahkan tekan tombol "Kembali"
            </ModalBody>

            <ModalFooter>
              <Button variant="outline" mr={3} onClick={onClose}>
                Kembali
              </Button>
              <Button colorScheme="green" onClick={answerQuestionHandler}>
                Kirim
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Stack>
    </ContentErrorHandler>
  );
};

export default Questionaire;
