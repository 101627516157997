import { matchRoutes, useLocation } from "react-router-dom";
import { ExtraRouteObject, ROUTE_OBJECT } from "routes";

export const useCurrentPath = (): ExtraRouteObject | null => {
  const location = useLocation();
  const result = matchRoutes(ROUTE_OBJECT, location);

  if (result != null) {
    const [{ route }] = result;
    return route;
  }

  return null;
};
