import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Switch,
  Text,
  useBoolean,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { COMMON_FORM, COMMON_FORM_ERROR, EVENT_PAGE } from 'constants/text';
import 'font-awesome-animation/css/font-awesome-animation.min.css';
import { Field, Formik, FormikErrors, FormikTouched } from 'formik';
import { EventSchedule, RegisterEventRequest } from 'models/event';
import moment from 'moment';
import { useState } from 'react';
import { useScreen } from 'usehooks-ts';
import { useRegisterEventMutation } from '../api';

const COLORS = ['#3182ce', '#D69E2E'];

type Props = {
  isOpen: boolean;
  toggleDrawer: any;
  eventName: string;
  schedule: EventSchedule | null;
  refetchDetail: any;
};

const RegisterDrawer = ({ isOpen, toggleDrawer, eventName, schedule, refetchDetail }: Props) => {
  const screenSize = useScreen();
  const toast = useToast();
  const [registerEvent, { isError, isLoading }] = useRegisterEventMutation();
  const [isMemberForm, setIsMemberForm] = useBoolean(!schedule?.openToPublic ?? true);

  const showToast = (isSuccess: boolean, description?: string, title?: string) => {
    if (isSuccess == false && (description == null || description == undefined)) {
      description = EVENT_PAGE.registerFallbackErrorDesc;
      title = EVENT_PAGE.registerFallbackErrorTitle;
    }
    if (isSuccess == true && (description == null || description == undefined)) {
      description = EVENT_PAGE.registerSuccessDesc;
      title = EVENT_PAGE.registerSuccessTitle;
    }

    toast({
      title: title,
      size: 'sm',
      description: description,
      status: isSuccess ? 'success' : 'error',
      duration: 9000,
      isClosable: true,
    });
  };

  const save = async (value: RegisterEventRequest) => {
    let promise = registerEvent(value)
      .unwrap()
      .then((e) => {
        refetchDetail();
        showToast(true);
        toggleDrawer();
      })
      .catch((e) => {
        refetchDetail();
        showToast(false, e.data.message);
      });
  };

  const renderMemberForm = (errors: FormikErrors<RegisterEventRequest>, touched: FormikTouched<RegisterEventRequest>) => {
    return (
      <>
        <FormControl isInvalid={!!errors.memberId && touched.memberId}>
          <FormLabel fontSize={14} htmlFor="memberId">
            {COMMON_FORM.memberId}
          </FormLabel>
          <Field
            as={Input}
            id="memberId"
            name="memberId"
            type="text"
            size="sm"
            validate={(value: any) => {
              let error;
              if (!value && isMemberForm == true) {
                error = COMMON_FORM_ERROR.memberIdEmpty;
              }
              return error;
            }}
          />
          <FormHelperText fontSize={12}>Contoh format 02126.001.0009.999</FormHelperText>
          <FormErrorMessage fontSize={12}>{errors.memberId}</FormErrorMessage>
        </FormControl>

        {/* <FormControl isInvalid={!!errors.dob && touched.dob}>
          <FormLabel fontSize={14} htmlFor="dob">
            {COMMON_FORM.dob}
          </FormLabel>
          <Field
            as={Input}
            id="dob"
            name="dob"
            type="date"
            size="sm"
            validate={(value: any) => {
              let error;
              if (!value) {
                error = COMMON_FORM_ERROR.dobEmpty;
              }
              return error;
            }}
          />
          <FormHelperText fontSize={12}>Format tanggal bulan/hari/tahun</FormHelperText>
          <FormErrorMessage fontSize={12}>{errors.dob}</FormErrorMessage>
        </FormControl> */}

        <FormControl isInvalid={!!errors.phoneNumber && touched.phoneNumber}>
          <FormLabel fontSize={14} htmlFor="phoneNumber">
            {COMMON_FORM.phone}
          </FormLabel>
          <Field
            as={Input}
            id="phoneNumber"
            name="phoneNumber"
            size="sm"
            validate={(value: any) => {
              let error;
              if (!value) {
                error = COMMON_FORM_ERROR.phoneEmpty;
              }
              return error;
            }}
          />
          <FormHelperText fontSize={12}>Contoh 0812345678910</FormHelperText>
          <FormErrorMessage fontSize={12}>{COMMON_FORM_ERROR.phoneEmpty}</FormErrorMessage>
        </FormControl>
      </>
    );
  };

  const renderNonMemberForm = (errors: FormikErrors<RegisterEventRequest>, touched: FormikTouched<RegisterEventRequest>) => {
    return (
      <>
        <FormControl isInvalid={!!errors.name && touched.name}>
          <FormLabel fontSize={14} htmlFor="name">
            {COMMON_FORM.name}
          </FormLabel>
          <Field
            as={Input}
            id="name"
            name="name"
            type="text"
            size="sm"
            validate={(value: any) => {
              let error;
              if (!value && isMemberForm == false) {
                error = COMMON_FORM_ERROR.nameEmpty;
              }
              return error;
            }}
          />
          <FormErrorMessage fontSize={12}>{errors.name}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.phoneNumber && touched.phoneNumber}>
          <FormLabel fontSize={14} htmlFor="phoneNumber">
            {COMMON_FORM.phone}
          </FormLabel>
          <Field
            as={Input}
            id="phoneNumber"
            name="phoneNumber"
            size="sm"
            validate={(value: any) => {
              let error;
              if (!value) {
                error = COMMON_FORM_ERROR.phoneEmpty;
              }
              return error;
            }}
          />
          <FormHelperText fontSize={12}>Contoh 0812345678910</FormHelperText>
          <FormErrorMessage fontSize={12}>{COMMON_FORM_ERROR.phoneEmpty}</FormErrorMessage>
        </FormControl>
      </>
    );
  };

  return (
    <Drawer
      isOpen={isOpen}
      placement={(screenSize?.width || 0) < 768 ? 'bottom' : 'right'}
      onClose={() => {
        toggleDrawer();
        setIsMemberForm.on();
      }}
      size={'md'}
    >
      <DrawerOverlay />
      <DrawerContent boxShadow="dark-lg">
        <DrawerHeader>Registrasi untuk {eventName}</DrawerHeader>
        <Formik
          initialValues={{
            memberId: '',
            name: '',
            phoneNumber: '',
            scheduleId: schedule?.id || '-1',
          }}
          onSubmit={async (values, actions) => {
            save(values);
            actions.setSubmitting(false);
          }}
        >
          {({ handleSubmit, resetForm, errors, touched }) => (
            <form onSubmit={handleSubmit}>
              <DrawerBody paddingX={{ base: 6, md: 8 }} backgroundColor="gray.50" textAlign="left">
                <VStack spacing={3} bg="white" p={4} boxShadow="lg" rounded="md" fontSize={14}>
                  <HStack alignSelf="start" alignItems="start">
                    <Text flexShrink="0" width="70px">
                      Tanggal :
                    </Text>
                    <Text> {moment.unix(schedule?.date || 0).format('dddd, DD MMM YYYY')}</Text>
                  </HStack>
                  <HStack alignSelf="start" alignItems="start">
                    <Text flexShrink="0" width="70px">
                      Jam :
                    </Text>
                    <Text>
                      {schedule?.startTime} - {schedule?.endTime}
                    </Text>
                  </HStack>
                  <HStack alignSelf="start" alignItems="start">
                    <Text flexShrink="0" width="70px">
                      Tempat :
                    </Text>
                    <Text>{schedule?.location}</Text>
                  </HStack>
                </VStack>

                <VStack marginTop={{ base: 6, md: 8 }} spacing={3} align="flex-start">
                  {schedule?.openToPublic == true ? (
                    <FormControl display="flex" alignItems="center">
                      <FormLabel htmlFor="isMemberForm" mb="0">
                        Apakah anda anggota CU Madani?
                      </FormLabel>
                      <Switch
                        isChecked={isMemberForm}
                        onChange={(e) => {
                          setIsMemberForm.toggle();
                          resetForm();
                        }}
                        id="isMemberForm"
                      />
                    </FormControl>
                  ) : (
                    <></>
                  )}

                  {isMemberForm ? renderMemberForm(errors, touched) : renderNonMemberForm(errors, touched)}
                </VStack>
              </DrawerBody>
              <DrawerFooter>
                <Button variant="outline" mr={3} onClick={toggleDrawer}>
                  {EVENT_PAGE.ctaCancel}
                </Button>
                <Button type="submit" colorScheme="blue">
                  {EVENT_PAGE.ctaRegister}
                </Button>
              </DrawerFooter>
            </form>
          )}
        </Formik>
      </DrawerContent>
    </Drawer>
  );
};

export default RegisterDrawer;
