import { ChevronDownIcon } from "@chakra-ui/icons";
import { Collapse, Flex, Icon, Link, Stack, Text, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import { NavItem, NAV_ITEMS } from "../constant";
import { Link as RouterLink } from "react-router-dom";

const MobileNavbar = () => {
  return (
    <Stack
      pos="fixed"
      w="100%"
      zIndex={"2"}
      marginTop={"74px"}
      paddingBottom={"-74px"}
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      display={{ md: "none" }}
      shadow="lg"
    >
      {NAV_ITEMS.map((navItem) => (
        <MobileNavbarItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavbarItem = ({ label, children, href }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Link
        as={RouterLink}
        to={href ?? "#"}
        _hover={{
          textDecoration: "none",
        }}
      >
        <Flex py={2} justify={"space-between"} align={"center"}>
          <Text fontWeight={600} color={useColorModeValue("gray.600", "gray.200")}>
            {label}
          </Text>
          {children && (
            <Icon as={ChevronDownIcon} transition={"all .25s ease-in-out"} transform={isOpen ? "rotate(180deg)" : ""} w={6} h={6} />
          )}
        </Flex>
      </Link>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack mt={2} pl={4} borderLeft={1} borderStyle={"solid"} borderColor={useColorModeValue("gray.200", "gray.700")} align={"start"}>
          {children &&
            children.map((child) => (
              <Link as={RouterLink} key={child.label} py={2} to={child.href ?? "#"}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

export default MobileNavbar;
