import { FL_QUESTIONAIRE_ITEM } from 'constants/text';
import { URLS } from 'constants/url';
import Event from 'pages/Event';
import Questionaire from 'pages/FinancialLiteracy/components/Questionaire';
import QuestionaireLandingPage from 'pages/FinancialLiteracy/components/QuestionaireLandingPage';
import QuestionaireResult from 'pages/FinancialLiteracy/components/QuestionaireResult';
import LandingPage from 'pages/LandingPage';
import Statistic from 'pages/Statistic';
import UnderConstruction from 'pages/UnderConstruction';
import { RouteObject, useRoutes } from 'react-router-dom';

export interface ExtraRouteObject extends RouteObject {
  hideNavbar?: boolean;
}

export const ROUTE_OBJECT: ExtraRouteObject[] = [
  { path: URLS.ROOT_LANDING, element: <LandingPage /> },

  { path: URLS.LOGIN, element: <UnderConstruction /> },
  { path: URLS.SIGN_UP, element: <UnderConstruction /> },

  { path: URLS.FL_QUESTIONAIRE, element: <QuestionaireLandingPage /> },
  { path: URLS.FL_QUESTIONAIRE_ITEM, element: <Questionaire />, hideNavbar: true },
  { path: URLS.FL_QUESTIONAIRE_RESULT_ITEM, element: <QuestionaireResult /> },
  { path: URLS.FL_TRACKER, element: <UnderConstruction /> },

  { path: URLS.STATISTIC, element: <Statistic /> },

  { path: URLS.UMKM, element: <UnderConstruction /> },
  { path: URLS.EVENT, element: <Event /> },
  { path: URLS.ANY, element: <LandingPage /> },
];

const Routes = () => {
  return useRoutes(ROUTE_OBJECT);
};

export default Routes;
