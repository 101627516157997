import { URLS } from "constants/url";

export interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
}

export const NAV_ITEMS: Array<NavItem> = [
  {
    label: 'Finansial',
    children: [
      {
        label: 'Financial Literacy',
        subLabel: 'Cek seberapa baik pengelolaan keuanganmu',
        href: URLS.FL_QUESTIONAIRE,
      },
      {
        label: 'Tracker',
        subLabel: 'Selalu pantau arus pemasukan dan pengeluaranmu',
        href: URLS.FL_TRACKER,
      },
    ],
  },
  {
    label: 'Statistik',
    href: URLS.STATISTIC,
  },
  {
    label: 'UMKM',
    href: URLS.UMKM,
  },
  {
    label: 'Acara',
    href: URLS.EVENT,
  },
];
