import { ButtonGroup, Container, HStack, IconButton, Image, Stack, Text, useBreakpointValue, VStack } from "@chakra-ui/react";
import BatikPattern from "assets/footer.jpg";
import Logo from "assets/logo.png";
import ColorModeSwitcher from "components/ColorModeSwitcher";
import { BLUE_DARKER } from "constants/colors";
import { FaInstagram, FaWhatsapp } from "react-icons/fa";

const Footer = () => (
  <Container as="footer" role="contentinfo" maxW="100%" padding={0}>
    <Image objectFit="cover" height={8} width="100%" src={BatikPattern}></Image>
    <Stack spacing={{ base: "4", md: "5" }} py={{ base: "8", md: "14" }} px={{ base: 2, md: 10 }} background={BLUE_DARKER}>
      <Stack justify="space-between" direction={{ base: "column", md: "row" }} align="center">
        <HStack>
          <Image height={50} src={Logo}></Image>
          <VStack align="stretch" spacing={0}>
            <Text
              textAlign={useBreakpointValue({ base: "left", md: "left" })}
              fontFamily={"heading"}
              fontWeight="semibold"
              fontSize={useBreakpointValue({ base: "10px", md: "14px" })}
              color="white"
            >
              Credit Union Madani
            </Text>
            <Text
              textAlign={useBreakpointValue({ base: "left", md: "left" })}
              fontFamily={"heading"}
              fontWeight="semibold"
              fontSize={useBreakpointValue({ base: "14px", md: "18px" })}
              color="white"
            >
              Business Development Center
            </Text>
          </VStack>
        </HStack>
        <ButtonGroup variant="ghost">
          <IconButton
            colorScheme="whiteAlpha"
            color="white"
            as="a"
            href="https://api.whatsapp.com/send?phone=6282273000577"
            aria-label="Whatsapp"
            icon={<FaWhatsapp fontSize="1.25rem" />}
          />
          <IconButton
            colorScheme="whiteAlpha"
            color="white"
            as="a"
            href="https://www.instagram.com/creditunionmadani"
            aria-label="Instagram"
            icon={<FaInstagram fontSize="1.25rem" />}
          />
          <ColorModeSwitcher justifySelf="flex-end" />
        </ButtonGroup>
      </Stack>
      <Text fontSize="x-small" color="white" textAlign={{ base: "center", md: "left" }}>
        &copy; {new Date().getFullYear()} Credit Union Madani. All rights reserved.
      </Text>
    </Stack>
  </Container>
);

export default Footer;
